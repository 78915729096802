<template>
  <v-card style="height: 100%; overflow: hidden; border-left: 3px solid rgb(76, 175, 80);">
    <MainModal
        :main="{ component: 'FlatNote', title: 'Журнал коментарів / дзвінків' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_flat_notes)"
        :modal="show_flat_notes_dialog"
        @updateItemModal="flatNotesUpdateItemModal"
    />
    <div class="work-place-table-items">
      <div class="work-place-table-items-header">
        <div class="work-place-table-items-row">
          <div class="work-place-table-items-col"></div>
          <div class="work-place-table-items-col">
            Дата
          </div>
          <div class="work-place-table-items-col" style="display: flex; align-items: center;">
            <div style="flex: 0 0 80px">Текст</div>
            <v-spacer></v-spacer>
            <v-tooltip bottom color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="openFlatNotesCreateDialog"
                       v-bind="attrs"
                       v-on="on"
                       class="grey lighten-4 mr-1">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Створити створити запис журналу коментарів/дзвінків</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div class="work-place-table-items-body">
        <div v-for="item in items" :key="item.id"
             @click="onFlatNotesItemClick(item)"
            class="work-place-table-items-row">
          <div class="work-place-table-items-col">
            <v-icon
                :color="getFlatNoteType(item.flat_note_type, 'color')"
                :size="17" class="ml-2"
            >
              {{ getFlatNoteType(item.flat_note_type, 'icon') }}
            </v-icon>
          </div>
          <div class="work-place-table-items-col">
            {{ item.note_date | formatDate('DD.MM.YYYY') }}
          </div>
          <div class="work-place-table-items-col">
            {{ item.notes }}
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import flatNoteAPI from "@/utils/axios/flat_notes";
import {ALERT_SHOW} from "@/store/actions/alert";
import {getFlatNoteType, getCurrentDate} from "@/utils/icons";

export default {
  name: "FlatNotesWorkPlace",
  props: ['person_hash', 'refresh'],
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters(
        {
          flat_current: 'getFlat',
        }
    )
  },
  data() {
    return {
      items: [],
      selected_flat_notes: {},
      show_flat_notes_dialog: false
    }
  },
  methods: {
    fetch(person_hash) {
      flatNoteAPI.get_all(person_hash)
          .then(response => response.data)
          .then(data => {
            this.items = data

            const counter = {count: 0, warning: 0}
            this.items.forEach(i => {
              if (!i.disable) {
                counter.count += 1

                if (i.flat_note_type === 'warning_call') {
                  counter.warning += 1
                }

                if (i.flat_note_type === 'warning_document') {
                  counter.warning += 1
                }

                if (i.flat_note_type === 'problem_person') {
                  counter.warning += 1
                }
              }
            })
            this.$emit('afterFetch', counter)
          })
          .catch(err => {
            this.$emit('afterFetch', {count: 0, warning: 0})
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          });
    },
    getFlatNoteType,
    getCurrentDate,
    flatNotesUpdateItemModal(payload) {
      this.show_flat_notes_dialog = false
      this.selected_flat_notes = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.person_hash
          this.fetch(person_hash)
        }
      }
    },
    openFlatNotesCreateDialog() {
      this.selected_flat_notes = {}
      this.show_flat_notes_dialog = true
    },
    onFlatNotesItemClick(payload) {
      this.selected_flat_notes = JSON.parse(JSON.stringify(payload))
      this.show_flat_notes_dialog = true
    },
  },
  mounted() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.person_hash
    if (person_hash) {
      this.fetch(person_hash)
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetch(payload)
        } else {
          this.items = []
        }
      }
    },
    refresh: {
      immediate: false,
      handler(payload) {
        if (payload) {
          const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.person_hash
          this.fetch(person_hash)
        }
      }
    }
  }
}
</script>


<style scoped lang="scss">
.work-place-table-items-row {
  display: flex;
  align-items: center;
  min-height: 24px;

  .work-place-table-items-col {
    padding: 3px;

    &:nth-child(1) {
      flex: 0 0 46px;
    }
    &:nth-child(2) {
      flex: 0 0 90px;
    }
    &:nth-child(3) {
      flex: 1;
    }
  }
}

  .work-place-table-items {
    line-height: .92rem;
    height: 100%;

    .work-place-table-items-header {
      font-weight: 500;
      background-color: #fcfcfc;
      height: 46px;

      .work-place-table-items-row {
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
        .work-place-table-items-col {
          font-size: .82rem;
          padding: 5px 3px;
        }
      }
    }

    .work-place-table-items-body {
      height: calc(100% - 40px);
      overflow: auto;

      .work-place-table-items-row {
        border-bottom: 1px solid #f6f6f6;
        font-size: .88rem;

        animation: ease-in-out all 100ms;

        &:hover {
          cursor: pointer;
          background-color: #f6f6f6;
        }
      }
    }

  }
</style>